import React from "react";
import ReactApexChart from "react-apexcharts";
import Chart from 'react-apexcharts';

const ApexChart = () => {

  const dates = [
    [1725864775725, 5783925],
    [1725778375725, 7128304],
    [1725691975725, 9345728],
    [1725605575725, 4271935],
    [1725519175725, 6203781],

    [1725432775725, 8129345],
    [1725346375725, 9367421],
    [1725259975725, 5637204],
    [1725173575725, 8234892],
    [1725087175725, 5349981],

    [1725000775725, 6843915],
    [1724914375725, 5128394],
    [1724827975725, 7598376],
    [1724741575725, 6134819],
    [1724655175725, 7362002],

    [1724568775725, 9102092],
    [1724482375725, 5364931],
    [1724395975725, 7468932],
    [1724309575725, 5230419],
    [1724223175725, 6045123],

    [1724136775725, 9284931],
    [1724050375725, 5117429],
    [1723963975725, 8425381],
    [1723877575725, 6351876],
    [1723791175725, 7083921],

    [1723704775725, 8376284],
    [1723618375725, 9509834],
    [1723531975725, 6922085],
    [1723445575725, 5359143],
    [1723359175725, 5934761],

    [1723272775725, 7583480],
    [1723186375725, 6311478],
    [1723099975725, 5203927],
    [1723013575725, 8472491],
    [1722927175725, 7416193],

    [1722840775725, 8158725],
    [1722754375725, 6039317],
    [1722667975725, 7351824],
    [1722581575725, 5736482],
    [1722495175725, 6192941],

    [1722408775725, 9269384],
    [1722322375725, 8153472],
    [1722235975725, 6823941],
    [1722149575725, 5930241],
    [1722063175725, 8215035],

    [1721976775725, 5039872],
    [1721890375725, 8234721],
    [1721803975725, 7586943],
    [1721717575725, 9421384],
    [1721631175725, 6157035],

    [1721544775725, 5638291],
    [1721458375725, 9348273],
    [1721371975725, 5176209],
    [1721285575725, 9501837],
    [1721199175725, 6314841],

    [1721112775725, 8369045],
    [1721026375725, 5972345],
    [1720939975725, 5128491],
    [1720853575725, 9472039],
    [1720767175725, 8223741],

    [1720680775725, 7391853],
    [1720594375725, 8364782],
    [1720507975725, 5176742],
    [1720421575725, 7283210],
    [1720335175725, 6820913],

    [1720248775725, 9147825],
    [1720162375725, 5320793],
    [1720075975725, 6123949],
    [1719989575725, 7548376],
    [1719903175725, 8153720],

    [1719816775725, 6701923],
    [1719730375725, 9486725],
    [1719643975725, 5372162],
    [1719557575725, 8423021],
    [1719471175725, 6134298],

    [1719384775725, 9147483],
    [1719298375725, 7051936],
    [1719211975725, 5623948],
    [1719125575725, 8524762],
    [1719039175725, 9156921],

    [1718952775725, 6378489],
    [1718866375725, 5932846],
    [1718779975725, 8234729],
    [1718693575725, 7342854],
    [1718607175725, 6120184],

    [1718520775725, 7279849],
    [1718434375725, 6412850],
    [1718347975725, 8361722],
    [1718261575725, 5783463],
    [1718175175725, 9156384],

    [1718088775725, 6478096],
    [1718002375725, 8351623],
    [1717915975725, 5218027],
    [1717829575725, 9162875],
    [1717743175725, 8246724],

    [1717656775725, 6108341],
    [1717570375725, 5179120],
    [1717483975725, 9046273],
    [1717397575725, 6728039],
    [1717311175725, 9405315],

    [1717224775725, 5930294],
    [1717138375725, 8349273],
    [1717051975725, 9457439],
    [1716965575725, 6201754],
    [1716879175725, 5131829],

    [1716792775725, 7345128],
    [1716706375725, 6220924],
    [1716619975725, 8457329],
    [1716533575725, 8209351],
    [1716447175725, 6164780]
  ];



  const chartOptions = {
    series: [
      {
        name: 'Transactions per Day',
        data: dates,
      }
    ],
    options: {
      chart: {
        type: 'line',
        height: 350,
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: true
        },
        toolbar: {
          autoSelected: 'zoom',
        },
        title: {
          text: 'Transactions per Day',
          align: 'left'
        }
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      title: {
        text: 'Polygon PoS Chain Daily Transactions Chart',
        align: 'left',
      },
      xaxis: {
        type: 'datetime',
        labels: {
          formatter: function (value: any) {
            const date = new Date(value);
            const options: any = { month: 'short', day: 'numeric' };
            return date.toLocaleDateString('en-US', options);
          }
        },
      },
      yaxis: {
        title: {
          text: 'Transactions per Day',
        },
        labels: {
          formatter: function (value: any) {
            return value.toLocaleString();
          }
        },
      },
      tooltip: {
        shared: true,
        x: {
          formatter: function (value: any) {
            const date = new Date(value);
            const options: any = { month: 'short', day: 'numeric', year: '2-digit' };
            return date.toLocaleDateString('en-US', options);
          }
        },
        y: {
          formatter: function (value: any) {
            return value.toLocaleString();
          }
        },
      },
      grid: {
        show: true,
        borderColor: '#f1f1f1',
        strokeDashArray: 5,
      },
    }
  };


  return (
    // <div>
    //   <div id="chart">
    //     <ReactApexChart
    //       options={state.options}
    //       series={state.series}
    //       type="area"
    //       height={350}
    //     />
    //   </div>
    // </div>
    // <Chart
    //   options={chartOptions}
    //   series={chartOptions.series}
    //   type="line"
    //   height={350}
    // />
    <></>
  );
};

export default ApexChart;
